<template>
  <div>
    <b-overlay :show="isTogging">
      <SevenPayForm :bank-account="bankAccountForm"  @on-toggle="toggleStatus"/>
    </b-overlay>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapActions} from 'vuex';

export default Vue.extend({
  name: 'AutoPaymentGatewayForm',
  components: {
    SevenPayForm: () => import('./seven-pay-form'),
  },
  props: {
    bankAccountForm: {
      type: Object,
      required: true,
      default: () => ({
        id: null,
        paymentGateways: null,
      })
    }
  },
  data() {
    return {
      isTogging: false,
    }
  },
  methods: {
    ...mapActions(['toggleBankingStatus', 'fetchBankAccount']),
    async toggleStatus(id) {
      this.isTogging = true
      await this.toggleBankingStatus({
        id,
        banking: 'payment-gateway'
      })
      await this.fetchBankAccount(this.bankAccountForm.id)
      this.isTogging = false
    }
  }
})
</script>

<style scoped>

</style>