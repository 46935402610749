<template>
    <div class="row">
      <div class="col-sm-4 col-lg-3">
        <h5>
          Payment Gateway
          <AutoBankingStatus :status="!!paymentGateway" />
          <br>
          <small class="text-muted">(รองรับฝากและถอน)</small>
        </h5>
      </div>

      <div class="col-sm-8 col-lg-9 pt-2">
        <b-form-checkbox
            v-if="paymentGateway"
            id="sevenPayStatus"
            :checked="paymentGateway.isEnabled"
            name="sevenPayStatus"
            switch
            @change="onToggle"
        >
          เปิดใช้งาน
        </b-form-checkbox>
        <AutoBankingLinkButton
            :status="!!paymentGateway"
            @click="$bvModal.show('seven-pay-gateway-modal')"
        />
        <SevenPayGatewayModal :agent-bank-account-id="bankAccount.id" :payment-gateway="paymentGateway" />
      </div>
    </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'AutoPaymentGatewaySevenPayForm',
  components: {
    SevenPayGatewayModal: () => import('./seven-pay-modal'),
    AutoBankingStatus: () => import('../auto-banking-status'),
    AutoBankingLinkButton: () => import('../auto-banking-link-button'),
  },
  props: {
    bankAccount: {
      type: Object,
      required: true,
      default: () => ({
        id: null,
        paymentGateways: [],
      })
    }
  },
  data() {
    return {
      paymentGateway: null,
    }
  },
  created() {
    this.paymentGateway = this.bankAccount.paymentGateways.find(({name}) => name === 'seven-pay')
    console.log(this.bankAccount.paymentGateways)
  },
  methods: {
    onToggle() {
      this.$emit('on-toggle', this.paymentGateway.id)
    },
  }
})
</script>

<style scoped>

</style>