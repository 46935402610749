<template>
  <b-modal
    id="approve-withdraw-modal"
    :body-class="approveType&&approveType ==='auto'?'bg-gradient-info':'bg-gradient-warning'"
    :header-class="approveType&&approveType ==='auto'?'bg-gradient-info':'bg-gradient-warning'"
    :title="approveType? (approveType ==='auto'?'ถอนออโต้':'ถอนมือ'):`${$t('credits.direct_deposit')}`"
    hide-footer
  >
    <b-row>
      <b-col md="6">
        <b-card
          v-if="txn && txn.userId"
          bg-variant="dark"
          class="text-center"
          header="ยอดฝากรวมทั้งหมด"
          header-text-variant="success"
          text-variant="white"
        >
          <b-card-text
            ><h3 class="text-white">{{
              txn.userTotalDeposit | currency
            }}</h3></b-card-text
          >
        </b-card>
      </b-col>

      <b-col md="6">
        <b-card
          v-if="txn && txn.userId"
          bg-variant="dark"
          class="text-center"
          header="ยอดถอนรวมทั้งหมด"
          header-text-variant="danger"
          text-variant="white"
        >
          <b-card-text
            ><h3 class="text-white">{{
              txn.userTotalWithdraw | currency
            }}</h3></b-card-text
          >
        </b-card>
      </b-col>
    </b-row>

    <b-card
      bg-variant="primary"
      class="text-center"
      header="ยอดที่แจ้งถอนมา"
      header-text-variant="primary"
      text-variant="white"
    >
      <b-card-text
        ><h3 class="text-white">{{ txn.amount | currency }}</h3></b-card-text
      >
    </b-card>

    <div class="text-center">
      <h6>วันที่ {{ txn.createdAt | datetime }}</h6>
    </div>
    <b-dropdown id="transferBankAccount" class="w-100 mb-3" variant="outline-dark">
      <template #button-content>
        เลือกบัญชีที่โอน
      </template>
      <template v-if="bankAccounts.length > 0">
        <b-dropdown-item
          v-for="bank in bankAccounts"
          :key="bank.id"
          block
          @click="selectedBank = bank"
        >
          <b-media vertical-align="center">
            <template #aside>
              <BankIcon :bank-code="bank.bankCode"/>
            </template>
            <template v-if="bank.bankCode !== BankCodeEnum.PAYMENT_GATEWAY">
              {{ bank.bankAccountNumber }}<br>
            </template>
            {{ bank.bankAccountName}}
          </b-media>
        </b-dropdown-item>
      </template>
      <b-dropdown-item v-else disabled>ไม่มีบัญชี</b-dropdown-item>
    </b-dropdown>
    <b-card v-if="selectedBank">
      <b-row align-v="center">
        <b-col>
          <b-media vertical-align="center">
            <template #aside>
              <BankIcon :bank-code="selectedBank.bankCode"/>
            </template>
            <template v-if="selectedBank.bankCode !== BankCodeEnum.PAYMENT_GATEWAY">
              {{ selectedBank.bankAccountNumber }}<br>
            </template>
            {{ selectedBank.bankAccountName}}
          </b-media>
        </b-col>
        <b-col cols="2">
           <feather
            class="icon-lg align-middle icon-dual-success"
            type="check-circle"
          ></feather>
        </b-col>
      </b-row>
      <template v-if="selectedBank && selectedBank.isRequiredOtp">
        <hr>
        <label class="text-danger" for="otp">กรุณากรอกเลข OTP ก่อนทำรายการ</label>
        <b-input-group>
          <b-input
            id="otp"
            v-model="approveForm.otp"
          >
          </b-input>
          <b-input-group-append>
            <b-overlay :show="isRequestingOtp" class="d-inline-block" rounded spinner-small>
              <b-button variant="outline-primary" @click="onSendOtp">ขอรหัส OTP</b-button>
            </b-overlay>
          </b-input-group-append>
        </b-input-group>
        <b-form-text v-if="withdrawOtpRef">
          Ref: {{withdrawOtpRef}}
        </b-form-text>
      </template>
    </b-card>

    <template v-if="approveType === 'manual'">
      <div v-if="image" class="text-center mb-3">
        <b-img :src="sampleImage" fluid />
      </div>
      <b-form-group
          label="รูปภาพ"
          label-cols-lg="2"
          label-cols-sm="12"
          label-for="file">
        <b-form-file
            id="file"
            v-model="image"
            accept="image/*"
            @change="uploadFile"
        >
        </b-form-file>
        <b-form-text>
          <b-link
              v-if="image"
              class="text-danger"
              @click="deleteImage"
          >
            {{ $t('buttons.remove')}}
          </b-link>
        </b-form-text>
      </b-form-group>

      <b-form-group
          label="หมายเหตุ"
          label-cols-lg="2"
          label-cols-sm="12"
          label-for="remark"
      >
        <b-form-input v-model="approveForm.remark" />
      </b-form-group>
    </template>

    <div class="text-right">
      <b-button class="mr-2" variant="light" @click="closeModal">
        {{ $t('buttons.cancel') }}
      </b-button>
      <b-overlay :show="isLoading" class="d-inline-block" rounded spinner-small>
        <b-button 
          :disabled="(selectedBank && selectedBank.isRequiredOtp && !approveForm.otp) || isLoading" block type="submit" 
          variant="primary"
          @click="onSubmit">
          {{ $t('buttons.confirm') }}
        </b-button>
      </b-overlay>
    </div>
  </b-modal>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {BankCodeEnum} from '@src/constants/bank';

export default {
  props: {
    txn: {
      type: Object,
      default: ()=> {},
    },
    approveType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      selectedBank: null,
      approveForm: {
        agentBankAccountId: '',
        isManual: true,
        remark: '',
        ref: '',
        otp: '',
        imageUrl: '',
      },
      image: null
    }
  },
  computed: {
    BankCodeEnum() {
      return BankCodeEnum
    },
    ...mapState({
      isLoading: (state) => state.withdraw.isApprovingWithdraw,
      isRequestingOtp: (state) => state.withdraw.isRequestingWithdrawOtp,
      withdrawOtpRef: (state) => state.withdraw.withdrawOtpRef,
    }),
    ...mapGetters(['withdrawBankAccounts', 'withdraws']),
    bankAccounts(){
      return this.withdrawBankAccounts.filter((bankAccount) => this.approveType === 'manual' || bankAccount.isAutoEnabled)
    },
    sampleImage() {
      return this.image ? URL.createObjectURL(this.image) : ''
    },
  },
  watch: {
    selectedBank(val){
      if(val){
        this.approveForm.agentBankAccountId = val.id
      }
    },
    isRequestOtpSuccess(val){
      if(val === true){
        // this.selectedBank = null
      }
    },
    withdrawOtpRef(val){
      if(val){
        this.approveForm.ref = val
      }
    },
  },
  mounted() {
    this.$root.$on('bv::modal::shown', () => {
      window.document.getElementById('approve-withdraw-modal').onpaste = (pasteEvent) => {
        if (!pasteEvent.clipboardData.files?.length) {
          return
        }
        this.image = pasteEvent.clipboardData.files[0]
      };
    })
  },
  created() {
    this.fetchWithdrawBankAccounts();
  },
  methods: {
    ...mapActions(['upload', 'fetchWithdrawBankAccounts', 'approveWithdraw', 'requestWithdrawOtp']),
    closeModal() {
      this.$bvModal.hide('approve-withdraw-modal')
      this.selectedBank = null
      this.image = null
      this.approveForm = {
        agentBankAccountId: '',
        imageUrl: '',
        isManual: true,
        remark: '',
        ref: '',
        otp: '',
      }
    },
    onSendOtp() {
      this.requestWithdrawOtp({
        id: this.txn.id,
        agentBankAccountId: this.approveForm.agentBankAccountId,
      })
    },
    uploadFile(event) {
      if (event.target.files[0]) {
        this.image = event.target.files[0]
      } else {
        this.deleteImage()
      }
    },
    deleteImage() {
      this.image = null
      const input = document.getElementById('image')
      if (input) {
        input.value = ''
      }
    },
    async onSubmit() {
      if (this.isLoading) {
        return
      }
      if(!this.approveForm.agentBankAccountId){
        this.$store.dispatch('notifyError', 'กรุณาเลือกบัญชี')
      } else {
        this.approveForm.isManual = this.approveType === 'manual'
        if(this.image) {
          this.approveForm.imageUrl = await this.upload(this.image)
          if(!this.approveForm.imageUrl) {
            return
          }
        }
        await this.approveWithdraw({
          id: this.txn.id,
          data: this.approveForm,
        })
        this.closeModal()
      }
    },
  },
}
</script>
